import { reactive, set } from 'vue'
import { get, forEach } from 'lodash'

export const useProgressNotificationSettings = () => {
  const typeSettingBlocks = reactive({})
  const initTypeSettingBlocks = (typeConfig) => {
    for (const typeKey in typeConfig) {
      set(typeSettingBlocks, typeKey, [])
    }
  }

  const filterSyncTypeSettingBlock = (data) => {
    forEach(data, item => {
      const type = get(item, 'type')
      if (type) typeSettingBlocks[type].push(item)
    })
  }

  return { typeSettingBlocks, initTypeSettingBlocks, filterSyncTypeSettingBlock }
}
