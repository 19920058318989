<template>
  <div class="notification-info-block">
    <header class="flex items-center justify-between">
      <div class="tags">
        <Tag v-if="displayData.isDefaultAll" type="action" size="small">預設通知</Tag>
        <Tag v-if="displayData.enabled" type="info" size="small">未啟用</Tag>
      </div>
      <div class="flex gap-[4px]">
        <div v-if="!displayData.isDefaultAll" class="remove-btn" @click="$emit('remove')">
          <SVGIcon icon="common-trash" width="16" height="16" fill="var(--gray-60)" />
        </div>
        <div class="edit-btn" @click="$emit('edit')">
          <SVGIcon icon="common-edit" width="16" height="16" fill="white" />
        </div>
      </div>
    </header>

    <section class="info-content">
      <FormInfoItem
        width="65px"
        title="發送渠道："
        :content="displayData.channels"
      />
      <FormInfoItem
        width="65px"
        title="發送時間："
        :content="displayData.sendTime"
      />
      <FormInfoItem
        width="65px"
        title="通知對象："
      >
        <span :class="{'text-danger': displayData.notifyTargetIsRemove}">{{ displayData.notifyTargetIsRemove ? '(未套用範本)' : displayData.notifyTarget }}</span>
      </FormInfoItem>
    </section>
    <section v-if="displayData.notifyTargetTemplate && !displayData.notifyTargetIsRemove" class="flex flex-col gap-[4px]">
      <div class="template-info-block">{{ displayData.notifyTargetTemplate }}</div>
    </section>
    <section v-if="displayData.targetCondition" class="info-content">
      <FormInfoItem
        width="65px"
        title="附加條件："
        :content="get(targetConditionTypeConfigs, `${displayData.targetCondition}.label`)"
      />
    </section>
    <section v-if="displayData.notifyContentTemplate.length" class="info-content">
      <FormInfoItem
        width="65px"
        title="通知內容："
      >
        <span :class="{'text-danger': displayData.notifyContentIsRemove}">{{ displayData.notifyContentIsRemove ? '(未套用範本)' : displayData.notifyTarget }}</span>
      </FormInfoItem>
    </section>
    <section v-if="displayData.notifyContentTemplate.length && !displayData.notifyContentIsRemove" class="flex flex-col gap-[4px]">
      <div v-for="item in displayData.notifyContentTemplate" :key="item.id" class="template-info-block">
        <span v-if="item.isDefault">系統預設 - </span>
        <span v-if="item.type === 'imageText'">圖文資訊範本 - </span>
        {{ item.name }}
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import Tag from '@/components/Tag/Tag.vue'
import SVGIcon from '@/components/SVGIcon.vue'
import { sendTimeType, sendTimeUnit } from '@/config/times'
import { targetConditionTypeConfigs } from '@/config/progressNotification'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
export default defineComponent({
  name: 'NotificationInfoBlock',
  components: { Tag, SVGIcon, FormInfoItem },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const displayData = computed(() => {
      const data = props.data
      const { id, isDefaultAll } = data
      const notifyTargetTemplate = get(data, 'NotifyTargetTemplate.name')
      const notifyTargetIsRemove = get(data, 'NotifyTargetTemplate.isRemove')
      const sendTime = renderSendTime(get(data, 'NotifySettings'))
      const settings = get(data, 'NotifySettings')
      // const test = []
      const notifyContentTemplate = []
      let channels, templates, enabled, notifyContentIsRemove
      settings.forEach(setting => {
        channels = get(setting, 'channels').join('、')
        templates = get(setting, 'ContentTemplates').map(templates => {
          const content = get(setting, 'contents').find(content => content.NotifyContentTemplateId === templates.id)
          return { ...templates, content }
        })

        templates.forEach(template => {
          const index = notifyContentTemplate.findIndex(item => item.id === template.id)
          if (index === -1) {
            notifyContentTemplate.push(template)
          }
        })

        // enabled = notifyContentTemplate.every(item => !get(item, 'content.enabled'))
        // enabled = !get(setting, 'enabled')
        enabled = settings.every(item => !get(item, 'enabled'))

        notifyContentIsRemove = get(setting, 'ContentTemplates[0].isRemove')
      })
      return {
        id,
        isDefaultAll,
        channels,
        sendTime,
        notifyTarget: notifyTargetTemplate ? '(套用範本)' : '所有服務項目',
        notifyTargetIsRemove,
        notifyTargetTemplate,
        notifyContent: isDefaultAll ? '(套用範本)' : '',
        notifyContentTemplate,
        notifyContentIsRemove,
        targetCondition: get(data, 'NotifySettings[0].targetCondition'),
        enabled,
      }
    })
    const renderSendTime = (data) => {
      data.sort((a, b) => get(a, 'targetIndex') - get(b, 'targetIndex'))
      const stringBuilder = data.map(item => {
        if (item.sendTimeType === 'now') {
          return get(sendTimeType, `${item.sendTimeType}.label`)
        } else {
          return get(sendTimeType, `${item.sendTimeType}.label`) + get(item, 'sendTimeOffset') + get(sendTimeUnit, `${item.sendTimeUnit}.label`) +
        (get(item, 'sendTimeOffsetSpecified') && get(item, 'sendTimeUnit') === 'day' ? get(item, 'sendTimeOffsetSpecified') : '')
        }
      }).join('、')
      return stringBuilder
    }

    return { displayData, get, targetConditionTypeConfigs }
  },
})
</script>

<style lang="postcss" scoped>
section {
  @apply m-0;
}
.notification-info-block {
    @apply bg-white rounded-[8px] p-[12px] w-[240px];
    @apply flex flex-col gap-[4px];
}
.edit-btn {
  @apply bg-primary-100 w-[24px] h-[24px] p-[4px] rounded-[4px];
  @apply cursor-pointer;
}
.remove-btn {
  @apply bg-gray-40 w-[24px] h-[24px] p-[4px] rounded-[4px];
  @apply cursor-pointer;
}

.remove-btn:hover, .edit-btn:hover {
  @apply opacity-70;
}

.template-info-block {
  @apply border border-gray-40 bg-gray-20 rounded-[4px] text-center;
  @apply py-[5px] px-[8px] text-[10px] text-gray-80;
}

.tags {
  @apply h-[24px] grid grid-flow-col gap-[4px];
}

.info-content {
  @apply text-sm leading-[17.38px];
}
::v-deep .form-info-item {
  @apply items-start
}
::v-deep .form-info-item .title {
  @apply text-gray-100;
}
</style>
