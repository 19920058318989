<template>
  <div class="">
    <header class="flex justify-between items-center">
      <p class="font-medium text-lg">預約通知</p>
      <div class="flex items-center gap-[8px]">
        <p>顯示</p>
        <BaseElSelect v-model="search" multiple collapse-tags clearable @change="refresh">
          <BaseElSelectOption
            v-for="option in notificationContentTemplateOptions"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </BaseElSelect>
      </div>
    </header>

    <!-- <div class="top-scroller">
      <div v-for="block in 15" :key="block" class="relative opacity-0">
        <div class="notification-stage-wrapper">
          <NotificationInfoBlock />
        </div>
      </div>
    </div> -->

    <div v-loading="loading" class="flex gap-[30px] overflow-x-auto pb-[20px]" style="padding-top: 30px">
      <div v-for="(notifyType) in reservationNotificationSettingTypeConfigs" :key="notifyType.value" class="relative flex flex-col gap-[12px] items-center w-[240px]">
        <p class="notification-title">{{ notifyType.label }}</p>
        <p v-if="showNotify(notifyType) && !loading" class="text-sm text-danger flex items-center gap-[4px]">
          此通知時機的訊息不會在 Line 發送
          <el-tooltip placement="right">
            <div slot="content" style="width:200px">
              未開啟此通知時機的 Line 訊息發送設定。請至「基本參數設定 > Line 串接設定 > 通知訊息設定」調整「{{ notifyType.configLabel }}」的通知開啟狀態
            </div>
            <span class="material-icons text-[16px]">help_outline</span>
          </el-tooltip>
        </p>
        <div v-for="notifySetting in groupedNotifySettingsDataList[notifyType.value]" :key="notifySetting.id" class="notification-stage-wrapper">
          <NotificationInfoBlock
            :data="notifySetting"
            @edit="onEditNotifyBlock(notifyType.value, notifySetting.id)"
            @remove="onRemoveNotifyBlock(notifySetting)"
          />
        </div>
        <div class="flex flex-col items-center gap-[12px]">
          <BaseElButton style="padding-top: 7.5px; padding-bottom: 7.5px;" plain @click="addNotificationSettingBlock(notifyType.value)">
            <div class="flex justify-center items-center">
              <p style="margin-right: 5px">新增限定對象通知</p>
              <MaterialIcon>add_outlined</MaterialIcon>
            </div>
          </BaseElButton>
          <BaseElButton v-if="showResetBtn(notifyType.value)" class="reset-btn" @click="onResetNotifyBlock(notifyType.value)">
            <p>還原為預設值</p>
          </BaseElButton>
        </div>
        <img v-if="notifyType.value !== reservationNotificationSettingTypeConfigs.appointmentEnd.value" class="stage-arrow" :src="dashedArrow">
      </div>
    </div>

    <BaseDialog
      v-if="modal.warning"
      width="566px"
      title="提醒"
      @confirm="confirmWarnDialog"
      @cancel="modal.warning = false"
      @close="modal.warning = false"
    >
      <div class="dialog-content">
        <p>如需調整「所有服務項目都會收到的通知」，請編輯「預設通知」。</p>
        <p>如需新增「只有限定對象才會收到」的通知，請點擊確認並繼續。</p>

        <BaseElCheckbox class="mt-[20px] mb-[40px]" label="30 天內不再顯示此提醒" @change="onChecked" />
      </div>
    </BaseDialog>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="onDelete"
    />
    <DeleteDialog
      v-if="modal.reset"
      :title="`將清除「${notificationName}」的所有設定，還原為預設值`"
      content="清除後無法復原。是否確定還原為預設值？"
      width="40%"
      btnString="還原"
      @close="modal.reset = false"
      @delete="onReset"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import NotificationInfoBlock from './components/NotificationInfoBlock.vue'
import MaterialIcon from '@/components/MaterialIcon.vue'
import dashedArrow from './components/dashed_arrow.svg'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { reservationNotificationSettingTypeConfigs } from '@/config/progressNotification'
import { useProgressNotificationSettings } from '@/use/useProgressNotificationSettings'
import { GetProgressNotificationSettings, DeleteProgressNotificationSettings, UpdateProgressNotificationSettings, ResetProgressNotificationSettings, GetNotifyContentTemplate } from '@/api/progressNotification'
import { useRouter } from 'vue-router/composables'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import notifyMessage from '@/config/notifyMessage'
import { get, isEmpty } from 'lodash'
import { GetLinePushConfig } from '@/api/shop'
export default defineComponent({
  name: 'ReservationProgressNotification',
  components: { NotificationInfoBlock, MaterialIcon, BaseDialog, DeleteDialog },
  setup (props, { emit }) {
    const { simpleFetch } = useFetch()
    const { initTypeSettingBlocks, typeSettingBlocks, filterSyncTypeSettingBlock } = useProgressNotificationSettings()
    const { shopId } = useShop()
    const router = useRouter()
    const loading = ref(true)
    const selectNotifyType = ref(null)
    const selectNotify = ref(null)
    initTypeSettingBlocks(reservationNotificationSettingTypeConfigs)
    const notifyConfig = ref(null)
    const search = ref(null)
    const allNotifySettingsDataList = ref([])
    const dontShowAgainConfig = ref(false)
    const modal = reactive({
      warning: false,
      delete: false,
      reset: false,
    })
    const notificationContentTemplateOptions = ref([])
    const groupedNotifySettingsDataList = computed(() => reGroupDataList(allNotifySettingsDataList.value))
    const showResetBtn = (type) => {
      return get(groupedNotifySettingsDataList.value, `[${type}].length`) > 1 || !get(groupedNotifySettingsDataList.value, `[${type}][0].isSameDefault`)
    }
    const reGroupDataList = (originDataList) => {
      const result = {}
      originDataList.forEach(item => {
        if (!result[item.type]) result[item.type] = []
        if (item.isDefaultAll) result[item.type].unshift(item)
        else result[item.type].push(item)
      })
      return result
    }

    const onEditNotifyBlock = (type, id) => {
      router.push({
        name: 'ReservationProgressNotificationSettingEdit',
        params: { type, id },
      })
    }
    const onResetNotifyBlock = (notifyType) => {
      selectNotifyType.value = notifyType
      modal.reset = true
    }
    const onChecked = (checked) => {
      dontShowAgainConfig.value = checked
    }
    const addNotificationSettingBlock = (notifyType) => {
      selectNotifyType.value = notifyType
      const dontShowAgain = JSON.parse(localStorage.getItem('dontShowAgain'))
      const now = new Date().getTime()
      if (dontShowAgain) {
        if (now > dontShowAgain.expiryTimestamp) {
          localStorage.removeItem('dontShowAgain')
          modal.warning = true
        } else {
          router.push({
            name: 'ReservationProgressNotificationSettingEdit',
            params: {
              shopId: shopId.value,
              type: selectNotifyType.value,
            },
          })
        }
      } else { modal.warning = true }
    }
    const onRemoveNotifyBlock = async (notify) => {
      selectNotify.value = notify
      modal.delete = true
    }
    const confirmWarnDialog = () => {
      const expiryTimestamp = new Date().getTime() + 30 * 24 * 60 * 60 * 1000 // 30 days
      const dontShowAgain = get(dontShowAgainConfig, 'value')
      if (dontShowAgain) localStorage.setItem('dontShowAgain', JSON.stringify({ dontShowAgain, expiryTimestamp }))
      modal.warning = false
      router.push({
        name: 'ReservationProgressNotificationSettingEdit',
        params: {
          shopId: shopId.value,
          type: selectNotifyType.value,
        },
      })
    }
    const notificationName = computed(() => {
      return get(reservationNotificationSettingTypeConfigs, `${selectNotifyType.value}.label`)
    })
    const onDelete = async () => {
      loading.value = true
      await simpleFetch(DeleteProgressNotificationSettings, { shopId: shopId.value, id: get(selectNotify.value, 'id') }, async () => {
        window.$message.success(notifyMessage.deleteSuccess)
        await refresh()
        modal.delete = false
      })
    }
    const onReset = () => {
      loading.value = true
      simpleFetch(ResetProgressNotificationSettings, { shopId: shopId.value, type: selectNotifyType.value }, async () => {
        await refresh()
        modal.reset = false
      })
    }

    const refresh = async () => {
      simpleFetch(GetProgressNotificationSettings, { shopId: shopId.value }, (res) => {
        if (isEmpty(search.value)) allNotifySettingsDataList.value = res
        else allNotifySettingsDataList.value = res.filter(item => search.value.includes(get(item, 'NotifySettings[0].contents[0].NotifyContentTemplateId')))
        loading.value = false
      })
    }
    const getLinePushConfig = async () => {
      try {
        const config = await GetLinePushConfig({ shopId: shopId.value })
        notifyConfig.value = config
      } catch (error) {
        this.$message.error(error.message)
      }
    }
    const showNotify = (type) => {
      const appointmentOpen = get(notifyConfig.value, 'appointmentOpen') // 預約建立
      const appointmentChangeTime = get(notifyConfig.value, 'appointmentChangeTime') // 預約變更
      const appointmentCancel = get(notifyConfig.value, 'appointmentCancel') // 預約取消
      const appointmentTomorrow = get(notifyConfig.value, 'appointmentTomorrow') // 預約提醒
      const appointmentEnd = get(notifyConfig.value, 'appointmentEnd') // 預約結束
      switch (type.value) {
      case 'appointmentCreate':
        return !appointmentOpen
      case 'appointmentChange':
        return !appointmentChangeTime
      case 'appointmentCancel':
        return !appointmentCancel
      case 'appointmentStart':
        return !appointmentTomorrow
      case 'appointmentEnd':
        return !appointmentEnd
      }
    }
    onMounted(async () => {
      await getLinePushConfig()
      simpleFetch(GetNotifyContentTemplate, { shopId: shopId.value, start: 0 }, (res) => {
        notificationContentTemplateOptions.value = res.map(item => ({ label: item.name, value: item.id }))
      })
      refresh()
    })

    return {
      loading,
      search,
      dashedArrow,
      modal,
      addNotificationSettingBlock,
      onRemoveNotifyBlock,
      confirmWarnDialog,
      onChecked,
      notificationName,
      onDelete,
      onReset,
      typeSettingBlocks,
      reservationNotificationSettingTypeConfigs,
      onEditNotifyBlock,
      groupedNotifySettingsDataList,
      onResetNotifyBlock,
      notificationContentTemplateOptions,
      refresh,
      showNotify,
      showResetBtn,
    }
  },
})
</script>

<style lang="postcss" scoped>
.notification-title {
    @apply border-2 border-primary-100 rounded-full;
    @apply px-[40px] py-[7px] font-bold text-md leading-[26.06px] text-primary-100;
}

.notification-stage-wrapper {
    @apply w-[240px] gap-[12px];
    @apply flex flex-col items-center justify-center;
}

.stage-arrow {
    @apply absolute top-0 right-[-52px];
}
::v-deep .el-dialog__header {
  @apply pb-0;
}
::v-deep .el-dialog__body {
  @apply py-[20px]
}
::v-deep .el-checkbox__label {
  @apply font-normal
}
.reset-btn {
  @apply bg-gray-20 text-gray-60 m-0 border-0 shadow-none;
}

.top-scroller {
  @apply flex gap-[30px] w-full overflow-y-hidden overflow-x-auto h-[16px] pt-[30px];
}
</style>
